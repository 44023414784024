import {
  Border,
  Padding,
  Radius,
  Shadow,
} from "@mpay/web-tickets/src/components/common/customizeProps";
import * as React from "react";

import { twMerge } from "../../tailwind/tailwindMerge";

export function Box({
  children,
  as = "div",
  style = undefined,
  className = undefined,
  border = "light",
  role = undefined,
  shadow = "basic",
  padding = "medium",
  radius = "large",
}: {
  children: React.ReactNode;
  isFullwidth?: boolean;
  style?: React.CSSProperties;
  classNames?: string;
  className?: string;
  border?: Border;
  role?: string;
  shadow?: Shadow;
  padding?: Padding;
  radius?: Radius;
  as?: React.ElementType<any>;
}) {
  if (style !== undefined) {
    console.warn("Custom styling applied to Box", style);
  }

  return React.createElement(
    as,
    {
      style: style,
      className: twMerge(
        "tw-bg-white tw-overflow-hidden",
        shadows[shadow],
        borders[border],
        paddings[padding],
        radiuses[radius],
        className
      ),
      role: role,
    },
    children
  );
}

const shadows: Record<Shadow, string> = {
  none: "",
  light: "tw-shadow",
  basic: "tw-shadow-lg",
};

const borders: Record<Border, string> = {
  none: "",
  light: "tw-border tw-border-gray-200",
  medium: "tw-border tw-border-gray-400",
};

const paddings: Record<Padding, string> = {
  none: "",
  light: "tw-p-2 md:tw-p-4",
  medium: "tw-p-4 md:tw-p-6",
  heavy: "tw-p-6 md:tw-p-8"
};

const radiuses: Record<Radius, string> = {
  none: "",
  large: "tw-rounded-lg",
};
