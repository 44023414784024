export const CUSTOMER_HOOK_FORM_DATA_COOKIE_NAME = "customer.hookformdata";
const cookieNameBase = `${CUSTOMER_HOOK_FORM_DATA_COOKIE_NAME}.tickets`;

export function ticketCustomValuesCookieName(ticketbasketTicketId: string) {
  return `${cookieNameBase}.[${ticketbasketTicketId}]`;
}

export function isCookieATicketCustomValueTicket(cookieName: string) {
  return cookieName.startsWith(cookieNameBase);
}
