"use client";

import { BrowserContextProvider } from "@mpay/web-tickets/src/components/common/browserContext";
import { SyncSentryUser } from "@mpay/web-tickets/src/sentry/user.client";
import { NProgressTopLoader } from "@mpay/web-tickets/src/utilities/NProgressToploader";
import { Transition } from "@mpay/web-tickets/src/utilities/hooks/useNearestTransition";
import { SkipNavLink } from "@reach/skip-nav";
import { SessionProvider as NextAuthProvider } from "next-auth/react";
import * as React from "react";

import { Cookies } from "./_components/Cookies";
import { SetDiscountCodeFromURL } from "./s/[clientVanityUrl]/_components/SetDiscountCodeFromUrl.client";
import { SetRevealCodeFromURL } from "./s/[clientVanityUrl]/_components/SetRevealCodeFromUrl.client";

export function LayoutWrapper({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Transition>
        <BrowserContextProvider>
          <NextAuthProvider>
            <SyncSentryUser />
            <div className="tw-relative tw-z-50">
              <Cookies />
              <SkipNavLink />
              <SetRevealCodeFromURL />
              <SetDiscountCodeFromURL />
              <NProgressTopLoader />
            </div>
            <div id="maxFlow">{children}</div>
          </NextAuthProvider>
        </BrowserContextProvider>
      </Transition>
    </>
  );
}
