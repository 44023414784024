import { CUSTOMER_HOOK_FORM_DATA_COOKIE_NAME } from "@mpay/web-tickets/app/(checkout)/s/[clientVanityUrl]/order/@hastickets/@next/_components/FormWrapper/cookies";
import * as Sentry from "@sentry/browser";
import { parseCookies } from "nookies";

import { getBasketStateFromCookie } from "../components/sections/public/Checkout/basketMachine/cookies.client";

export function syncSentryPublicCheckout() {
  try {
    const basket = getBasketStateFromCookie();
    Sentry.setContext("Basket", {
      ...basket.basket,
      basketString: JSON.stringify(basket.basket),
    });

    try {
      const formikData = parseCookies()[CUSTOMER_HOOK_FORM_DATA_COOKIE_NAME];
      Sentry.setContext("Checkout Context", JSON.parse(formikData));
    } catch {}
  } catch {}
}
