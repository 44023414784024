const TRUE_VALUE = "true";
const FALSE_VALUE = "false";
export type OptOutValue = null | typeof TRUE_VALUE | typeof FALSE_VALUE;

export const optOutOfCookies = () => {
  window.localStorage.setItem("OptOutCookies", TRUE_VALUE);
};
export const optInToCookies = () => {
  window.localStorage.setItem("OptOutCookies", FALSE_VALUE);
};

export const getOptOutValue = (): OptOutValue => {
  return window.localStorage.getItem("OptOutCookies") as OptOutValue;
};

export const isOptedInToCookies = () => {
  return getOptOutValue() === TRUE_VALUE;
};
