"use client";

import * as React from "react";

const TransitionContext = React.createContext<
  [boolean, React.TransitionStartFunction]
>([
  false,
  () => {
    console.warn("No nearest transition context, please wrap in Transition");
  },
]);

export function useNearestTransition() {
  return React.useContext(TransitionContext);
}

export function Transition({ children }: { children: React.ReactNode }) {
  const transition = React.useTransition();
  return (
    <TransitionContext.Provider value={transition}>
      <>{children}</>
    </TransitionContext.Provider>
  );
}
