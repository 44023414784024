import { ErrorBoundary } from "@mpay/web-tickets/src/components/common/ErrorBoundary";
import {
  getCustomerTagId,
  getSessionId,
} from "@mpay/web-tickets/src/components/sections/public/Checkout/basketMachine/cookies.client";
import { getUserOrLoading } from "@mpay/web-tickets/src/utilities/user/userContext";
import * as Sentry from "@sentry/browser";
import { usePathname } from "next/navigation";
import React from "react";

import { syncSentryPublicCheckout } from "./publicCheckout";

export function SyncSentryUser() {
  return (
    <ErrorBoundary fallback={<></>}>
      <React.Suspense fallback={null}>
        <SyncInner />
      </React.Suspense>
    </ErrorBoundary>
  );
}

function SyncInner() {
  const [user, loading] = getUserOrLoading();
  React.useEffect(() => {
    if (!loading) {
      if (user) {
        const sessionId = getSessionId();
        const customerTagId = getCustomerTagId();

        if (user.type === "customer") {
          Sentry.setUser({
            id: `customer:${customerTagId}`,
          });
        } else {
          Sentry.setUser({
            username: user.username,
            id: user.id,
          });
        }

        Sentry.setContext("User Extra", {
          clientId: user.clientId,
          id: user.id,
          username: user.username,
          type: user.type,
          sessionId,
          customerTagId,
        });
      }
    }
  }, [user, loading]);

  const pathname = usePathname();
  React.useEffect(() => {
    syncSentryPublicCheckout();
  }, [pathname]);

  return null;
}
