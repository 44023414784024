"use client";

import { normalizeRevealCode } from "@mpay/core/src/models/ticket/normalizeRevealCode";
import { ErrorBoundary } from "@mpay/web-tickets/src/components/common/ErrorBoundary";
import { setBasketStateCookie } from "@mpay/web-tickets/src/components/sections/public/Checkout/basketMachine/cookies.client";
import { getBasketStateFromCookie } from "@mpay/web-tickets/src/components/sections/public/Checkout/basketMachine/cookies.client";
import { useRouter } from "next/navigation";
import { useSearchParams } from "next/navigation";
import * as React from "react";

import { promoQueryParam } from "./codes.contract";

export function SetRevealCodeFromURL() {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={null}>
        <SetRevealCodeFromURLBase />
      </React.Suspense>
    </ErrorBoundary>
  );
}

function SetRevealCodeFromURLBase() {
  const searchParams = useSearchParams();
  const queryCode = normalizeRevealCode(
    searchParams?.get(promoQueryParam) ?? ""
  );
  const router = useRouter();
  const basket = getBasketStateFromCookie();

  const revealCodes = queryCode
    .split(",")
    .filter((x) => x != null && x.length > 0)
    .map((code) => normalizeRevealCode(code));

  React.useEffect(() => {
    let hasUpdatedBasket = false;
    for (const baseCode of revealCodes) {
      const code = normalizeRevealCode(baseCode);
      if (!basket.basket.revealCodes.map((x) => x.code).includes(code)) {
        hasUpdatedBasket = true;
        basket.basket.revealCodes.push({ code: code });
      }
    }

    if (hasUpdatedBasket) {
      setBasketStateCookie(basket);
      router.refresh();
    }
  }, [revealCodes.join(","), basket.basket.revealCodes.join(",")]);

  return null;
}
