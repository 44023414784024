"use client";

import { normalizeDiscountCode } from "@mpay/core/src/models/ticket/normalizeDiscountCode";
import { Branded } from "@mpay/core/src/utilities/Brand";
import { ErrorBoundary } from "@mpay/web-tickets/src/components/common/ErrorBoundary";
import { addDiscountCode } from "@mpay/web-tickets/src/components/models/basket/discountCodes/_lib/addDiscountCode.server";
import { getBasketStateFromCookie } from "@mpay/web-tickets/src/components/sections/public/Checkout/basketMachine/cookies.client";
import { useNearestTransition } from "@mpay/web-tickets/src/utilities/hooks/useNearestTransition";
import { useParams, useSearchParams } from "next/navigation";
import * as React from "react";

import { discountQueryParam } from "./codes.contract";

export function SetDiscountCodeFromURL() {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={null}>
        <SetDiscountCodeFromURLBase />
      </React.Suspense>
    </ErrorBoundary>
  );
}

function SetDiscountCodeFromURLBase() {
  const params = useParams<{ eventId: string }>();

  const searchParams = useSearchParams();
  const queryCode = normalizeDiscountCode(
    searchParams?.get(discountQueryParam) ?? ""
  );
  const basket = getBasketStateFromCookie();

  const [_, startTransition] = useNearestTransition();

  const discountCodes = queryCode
    .split(",")
    .filter((x) => x != null && x.length > 0)
    .map((code) => normalizeDiscountCode(code));

  React.useEffect(() => {
    startTransition(async () => {
      try {
        for (const newCode of discountCodes) {
          if (
            !basket.basket.discountCodes.some((code) => code.code === newCode)
          ) {
            await addDiscountCode(
              decodeURIComponent(params!.eventId) as Branded<string, "EventId">,
              {
                code: newCode,
              }
            );
          }
        }
      } catch (e) {
        console.log("Failed to add discount code", e);
      }
    });
  }, [discountCodes.join(","), basket.basket.discountCodes.join(",")]);

  return null;
}
