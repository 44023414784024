"use client";

import { Transition } from "@headlessui/react";
import { Box } from "@mpay/web-tickets/src/components/common/Box";
import { BrowserOnlyFadeIn } from "@mpay/web-tickets/src/components/common/BrowserOnly";
import { Button } from "@mpay/web-tickets/src/components/common/Button/Button";
import { ErrorBoundary } from "@mpay/web-tickets/src/components/common/ErrorBoundary";
import { NextLink } from "@mpay/web-tickets/src/components/common/Link/NextLink";
import MobileOnly from "@mpay/web-tickets/src/components/common/helpers/mobile/MobileOnly";
import NotMobile from "@mpay/web-tickets/src/components/common/helpers/mobile/NotMobile";
import {
  optInToCookies,
  optOutOfCookies,
  getOptOutValue,
} from "@mpay/web-tickets/src/utilities/cookies";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";
import * as React from "react";
import { useUpdate } from "react-use";

export function CookiesBox({
  update,
}: {
  forceRender?: boolean;
  update: () => void;
}) {
  return (
    <>
      <NotMobile>
        <Box className="tw-flex tw-gap-x-8 tw-items-center" border="light">
          <div className="tw-text-sm tw-text-gray-700 tw-flex-shrink">
            We use cookies for analytics.{" "}
            <NextLink applyLinkStyle href="/privacy-policy">
              Learn more.
            </NextLink>
          </div>
          <div className="tw-flex tw-gap-x-2 tw-gap-y-2">
            <Button
              visualSize="small"
              color="white"
              isBasic
              onClick={() => {
                update();
                optOutOfCookies();
              }}
              aria-label={"Reject cookies"}
              isFullwidth
            >
              No
            </Button>
            <Button
              visualSize="small"
              color="green"
              onClick={() => {
                update();
                optInToCookies();
              }}
              aria-label={"Accept cookies"}
            >
              Okay
            </Button>
          </div>
        </Box>
      </NotMobile>
      <MobileOnly>
        <Box
          className="tw-flex tw-gap-x-2 tw-items-center tw-justify-between tw-w-full tw-rounded-b-none"
          border="light"
        >
          <div className="tw-text-sm tw-text-gray-700 tw-flex-shrink">
            We use cookies for analytics.{" "}
            <NextLink applyLinkStyle href="/privacy-policy">
              Learn&nbsp;more.
            </NextLink>
          </div>
          <div className="tw-flex tw-gap-x-2 tw-gap-y-2">
            <Button
              visualSize="small"
              color="white"
              isBasic
              onClick={() => {
                update();
                optOutOfCookies();
              }}
              aria-label={"Reject cookies"}
              isFullwidth
            >
              No
            </Button>
            <Button
              visualSize="small"
              color="green"
              onClick={() => {
                update();
                optInToCookies();
              }}
              aria-label={"Accept cookies"}
            >
              Okay
            </Button>
          </div>
        </Box>
      </MobileOnly>
    </>
  );
}

export function Cookies() {
  return (
    <BrowserOnlyFadeIn serverRender={null}>
      <ErrorBoundary code="COOKIES" fallback={<></>}>
        <CookiesBrowserOnly />
      </ErrorBoundary>
    </BrowserOnlyFadeIn>
  );
}

function CookiesBrowserOnly() {
  const currentPath = usePathname()!;
  const optOutValue = getOptOutValue();
  const update = useUpdate();
  const show = optOutValue === null;

  if (
    ["/orders/[orderSecret]/pdf", "/orders/[orderSecret]/print"].includes(
      currentPath
    )
  ) {
    return null;
  }

  return (
    <Transition show={show} className="tw-fixed tw-bottom-0 tw-w-full">
      <Transition.Child
        leave="tw-transition-opacity tw-duration-300"
        leaveFrom="tw-opacity-100"
        leaveTo="tw-opacity-0"
      >
        {/** TODO: Confirm background colours */}
      </Transition.Child>
      <div className="tw-fixed tw-bottom-0 sm:tw-mb-8 tw-right-0 tw-flex tw-justify-center md:tw-justify-end sm:tw-pr-8 tw-w-full sm:tw-w-auto">
        <Transition.Child
          leave="tw-transition tw-ease-in-out tw-transform tw-duration-300"
          leaveFrom="tw-translate-y-0"
          leaveTo="tw-translate-y-32"
        >
          <CookiesBox update={update} />
        </Transition.Child>
      </div>
    </Transition>
  );
}
